import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M11 1c1.05 0 1.918.82 1.994 1.851L13 3v13l-5-3-5 3V3c0-1.05.82-1.918 1.851-1.994L5 1zm-1 2H6c-.51 0-.935.388-.993.884L5 4v8.3l3-1.8 3 1.8V4c0-.51-.388-.935-.884-.993z"
    }, null, -1)
  ])))
}
export default { render: render }