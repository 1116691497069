<template>
  <div v-show="file !== 'pending'">
    <CommonButton
      border="blue"
      bg="bg-gray-200"
      text="text-black"
      bg-hover="hover:none"
      text-hover="hover:none"
      @click.prevent="toggle"
      :size="small ? 'small' : 'medium'"
    >
      <span
        class="bg-blue rounded-full text-white justify-center p-1 text-center inline-flex items-center"
        :class="small ? '-ml-1.5 mr-1' : '-ml-2.5 mr-2'"
      >
        <IconPause
          v-if="isPlaying && url === props.file"
          id="icon-pause"
          class="fill-current"
          :class="small ? 'h-2.5 w-2.5' : 'h-3.5 w-3.5'"
        />
        <IconPlay
          v-else
          id="icon-play"
          class="fill-current"
          :class="small ? 'h-2.5 w-2.5' : 'h-3.5 w-3.5'"
        />
      </span>
      <div class="font-bold">
        <!-- add 'Lyt' to translation -->
        {{ small ? $t('Listen') : $t('ListenToArticle') }}
        <span v-if="duration">
          <span>•</span>
          {{ formatSecondsToMinuteSeconds(duration - currentTime) }}
        </span>
      </div>
    </CommonButton>
  </div>
</template>

<script setup lang="ts">
import IconPlay from '~/assets/icons/play.svg?component'
import IconPause from '~/assets/icons/pause.svg?component'
const props = defineProps<{
  small?: boolean
  file: string
  headline?: string
  duration?: number | null
  image?: string
}>()
const currentTime = ref(0)
const { isPlaying, play, url, audioCurrentTime } = useAudioPlayer()
function toggle() {
  // if playing, stop
  if (isPlaying.value && url.value === props.file) {
    isPlaying.value = false
  } else {
    playAudio()
  }
}

const playAudio = () => {
  // Track plays
  play({
    url: props.file,
    title: props.headline,
    imageUrl: props.image,
  })
}
watch(audioCurrentTime, (newValue) => {
  if (url.value !== props.file) return
  if (typeof newValue === 'number') {
    currentTime.value = newValue
  }
})
</script>
