export default function appendToFilename(filename: string, string: string) {
  if (!filename) return false

  const dotIndex = filename.lastIndexOf('.')
  if (dotIndex === -1) return filename + string
  else
    return (
      filename.substring(0, dotIndex) + string + filename.substring(dotIndex)
    )
}
