<template>
  <span
    v-if="userStore.user && userStore.user.loginType !== 'IP'"
    class="saveForLater flex list-label w-auto items-center opacity-35 group hover:opacity-100 transition-opacity duration-100 relative"
    :class="{ 'md:w-16': !small, 'opacity-100': saved }"
    @click.prevent="addRemoveToReadingList"
  >
    <SaveIcon v-if="!saved" class="w-4 h-4 inline-block mr-1" />
    <SavedIcon
      v-if="saved"
      class="w-4 h-4 inline-block text-blue fill-current mr-1"
    />
    <span
      v-if="!saved && !small"
      :class="{ 'opacity-100': index === 0 }"
      class="absolute left-0 whitespace-nowrap transform translate-x-5 hidden md:inline-block opacity-0 transition-opacity duration-100 group-hover:opacity-100"
      >{{ $t('SaveForLater') }}</span
    >
    <span
      v-if="saved && !small"
      class="absolute left-0 whitespace-nowrap transform translate-x-5 hidden md:inline-block"
      >{{ $t('Saved') }}</span
    >
  </span>
</template>

<script setup lang="ts">
import SaveIcon from '@/assets/icons/save.svg?component'
import SavedIcon from '@/assets/icons/saved.svg?component'

const props = withDefaults(
  defineProps<{ small: boolean; index: number; articleId: number }>(),
  { small: false, index: -1 }
)

const userStore = useUserStore()
const nuxtApp = useNuxtApp()
const indexStore = useIndexStore()

const saved = computed(() => {
  if (userStore.user?.readingList) {
    for (let i = 0; i < userStore.user.readingList.length; i++) {
      const item = userStore.user.readingList[i]

      if (item.article?.id === props.articleId) {
        return true
      }
    }

    return false
  } else {
    return false
  }
})
const addRemoveToReadingList = async () => {
  if (userStore.user) {
    if (saved.value) {
      nuxtApp.$api.user.removeFromReadingList(
        userStore.user.userId,
        props.articleId
      )
    } else {
      await nuxtApp.$api.user.saveToReadingList(
        userStore.user.userId,
        props.articleId,
        indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1
      )
    }

    await userStore.refreshUser()
  }
}
</script>
