import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2zM8 3.742l-.36.002c-1.352.022-2.42.224-3.073 1.812-.65 1.584-1.607 6.679-1.607 6.679h1.25a.82.82 0 0 0 .771-.543l.139-.484q.022-.074.034-.109C5.46 10.253 6.524 9.746 8 9.746c1.477 0 2.54.507 2.846 1.353l.034.109c.036.12.089.307.138.484a.82.82 0 0 0 .773.543h1.249l-.343-1.722c-.338-1.63-.86-3.972-1.264-4.957C10.723 3.83 9.523 3.742 8 3.742m0 1.285c.814 0 1.37.123 1.608.611l.083.184c.226.548.398 1.34.773 2.956l-.274-.072A9.8 9.8 0 0 0 8 8.431a9.7 9.7 0 0 0-2.464.347l.378-1.618c.177-.73.306-1.17.478-1.522.238-.488.794-.611 1.608-.611"
    }, null, -1)
  ])))
}
export default { render: render }